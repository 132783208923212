// App.js
import { faTelegram, faWeibo, faWeixin, faXTwitter } from '@fortawesome/free-brands-svg-icons';
import { faLink, faRss, faShareNodes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ConfigProvider, FloatButton, Popover, Spin, theme as antdTheme } from 'antd';
import React, { Suspense, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './App.css';
import { ThemeContext } from './components/ThemeSwitcher';
import i18n from './i18n';
import './index.css';
import './styles/tailwind.css';
// import { useNavigate } from 'react-router-dom';
const Home = React.lazy(() => import('./Home'));
const NuggetPage = React.lazy(() => import('./components/NuggetPage')); // 确保导入 NuggetPage 组件
const NotFound = React.lazy(() => import('./components/NotFound'));
const Aura = React.lazy(() => import('./navbar/aura'));
const FavMusics = React.lazy(() => import('./aura/music_fav'));
const FavMovies = React.lazy(() => import('./aura/movie_fav'));
const FavBooks = React.lazy(() => import('./aura/book_fav'));
const WrappedMusic = React.lazy(() => import('./wrapped/music'));
const Random = React.lazy(() => import('./navbar/random')); // Import the new ClickMe component
const Posts = React.lazy(() => import('./navbar/posts')); // Import the post component
const About = React.lazy(() => import('./navbar/about'));
const AllMusicPage = React.lazy(() => import('./catagories/allmusic'));
const AllVideoPage = React.lazy(() => import('./catagories/allvideo'));
const PostPage = React.lazy(() => import('./components/PostPage'));
const Supplemental = React.lazy(() => import('./components/supp'));



const App = () => {
  const [language, setLanguage] = useState(localStorage.getItem('language') || 'en');
  useEffect(() => {
    localStorage.setItem('language', language); // 更新 localStorage 的值
    i18n.changeLanguage(language);
  }, [language]);
  const changeLanguage = () => {
    if (language === 'en') {
      setLanguage('zh');
    } else {
      setLanguage('en');
    }
  };


  const { t } = useTranslation();
  const { theme, toggleTheme } = useContext(ThemeContext);

  const [copySuccess, setCopySuccess] = useState('');

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(window.location.href);
      setCopySuccess(t('linkCopied'));
    } catch (err) {
      setCopySuccess(t('copyFailed'));
    }
  };

  const content = (
    <div>
      <a href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(window.location.href)}&text=Check%20this%20page`} style={{ marginLeft: '20px' }} target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faXTwitter} style={{ fontSize: '16px' }} />
      </a>
      <a href={`http://service.weibo.com/share/share.php?url=${encodeURIComponent(window.location.href)}&text=Check%20this%20page`} style={{ marginLeft: '20px' }} target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faWeibo} style={{ fontSize: '16px' }} />
      </a>
      <a href={`https://www.douban.com/share/service?url=${encodeURIComponent(window.location.href)}&text=Check%20this%20page`} style={{ marginLeft: '20px' }} target="_blank" rel="noopener noreferrer">
        <p style={{ fontSize: '16px', fontWeight: 'bold', display: 'inline' }}>豆</p>
      </a>
      <button onClick={copyToClipboard} style={{ marginLeft: '20px' }}>
        <FontAwesomeIcon icon={faLink} style={{ fontSize: '16px' }} /></button>
      {copySuccess && <p style={{ color: 'green' }}>{copySuccess}</p>}
    </div>
  );

  const text = <span>{t('aura')}</span>;
  const auracontent = (
    <div>
      <p><a href="/aura/books">{t('mybooks')}</a></p>
      <p><a href="/aura/movies">{t('mymovies')}</a></p>
      <p><a href="/aura/musics">{t('mymusics')}</a></p>
    </div>
  );

  const darkmodeMoon = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill={theme === 'light' ? 'black' : 'white'}><path d="M11.993,3a9.326,9.326,0,0,0-1.138,4.477,8.8,8.8,0,0,0,8.569,9.015c.2,0,.385-.017.576-.03A8.5,8.5,0,0,1,12.569,21,8.8,8.8,0,0,1,4,11.985,8.83,8.83,0,0,1,11.993,3Z" /></svg>

  return (
    <ConfigProvider theme={{
      token: {
        colorPrimary: '#13c2c2',
      }
    }}>
      {/* <div style={{zIndex: theme === 'dark' ? 1 : 0}}> */}
      <div>
        <header id='header'>
          <div className='header-container'>
            <span className="header-top-left">
              <a id='brand' href='/' className='header-title'>
                {t('rambles')}
                {/* <img src={theme === 'light' ? logo : darklogo} alt='logo' style={{ display: 'inline-block', height: '28px'}}/> */}
              </a>
            </span>
            <span className="header-top-right">
              <nav id='nav' style={{ display: 'flex', alignItems: 'center' }}>
                <ConfigProvider
                  theme={{
                    algorithm: theme === 'light' ? antdTheme.defaultAlgorithm : antdTheme.darkAlgorithm,
                  }}
                >
                  <Popover placement="bottomLeft" title={text} content={auracontent}>
                    <a className='rainbow-link' href='/aura' aria-label='Aura'>
                      <svg width="48px" viewBox="0 0 48 17.6">
                        <defs>
                          <linearGradient id="rainbow" x1="0" x2="100%" y1="0" y2="0">
                            <stop offset="0%" stopColor="red" />
                            <stop offset="14%" stopColor="orange" />
                            <stop offset="28%" stopColor="yellow" />
                            <stop offset="42%" stopColor="green" />
                            <stop offset="57%" stopColor="blue" />
                            <stop offset="71%" stopColor="indigo" />
                            <stop offset="85%" stopColor="violet" />
                          </linearGradient>
                        </defs>
                        <text x="50%" y="53%" dominantBaseline="middle" textAnchor="middle" fill="url(#rainbow)"
                          style={{ fontSize: '16px', fontFamily: 'Arial', fontWeight: 'normal' }}>
                          {t('aura')}
                        </text>
                      </svg>
                    </a>
                  </Popover>
                </ConfigProvider>
                <a href='/posts'>{t('posts')}</a>
                <a href='/random'>{t('random')}</a>
                <a href='/about'>{t('about')}</a>
              </nav>
            </span>
          </div>
        </header>
        <Router>
          <Suspense fallback={<Spin tip="Loading" size="large"><div className="content" /></Spin>}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/aura" element={<Aura />} />
              <Route path="/aura/musics" element={<FavMusics />} />
              <Route path="/aura/movies" element={<FavMovies />} />
              <Route path="/aura/books" element={<FavBooks />} />
              <Route path="/wrapped/music" element={<WrappedMusic />} />
              <Route path="/posts" element={<Posts />} />
              <Route path="/posts/:id" element={<PostPage />} />
              <Route path="/random" element={<Random />} />
              <Route path="/about" element={<About />} />
              <Route path='/supp' element={<Supplemental />} />
              <Route path="/categories/music" element={<AllMusicPage />} />
              <Route path="/categories/picture" element={<AllVideoPage />} />
              <Route path="/NotFound" element={<NotFound />} />
              <Route path="/:id" element={<NuggetPage />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Suspense>
        </Router>
        <ConfigProvider
          theme={{
            algorithm: theme === 'light' ? antdTheme.defaultAlgorithm : antdTheme.darkAlgorithm,
          }}
        >
          <FloatButton.Group
            shape="circle"
            style={{
              right: 24,
            }}>
            <FloatButton
              aria-label='change theme'
              icon={darkmodeMoon}
              // tooltip={<div>{t('darkmode')}</div>}
              type={theme === 'light' ? 'default' : 'primary'}  // 根据主题来设置类型
              onClick={(e) => {
                e.preventDefault();
                toggleTheme();  // 在这里调用 toggleTheme
              }}
            />
            <FloatButton
              aria-label='change language'
              // tooltip={<div>{t('changetochinese')}</div>}
              icon={<span style={{ fontWeight: "bold" }}>中</span>}
              type={language === 'en' ? 'default' : 'primary'}  // 根据主题来设置类型
              onClick={changeLanguage}
            />
            <FloatButton.BackTop visibilityHeight={500} />
          </FloatButton.Group>
        </ConfigProvider>
        {/* Footer */}
        <footer id='footer'>
          <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
            <a href="/about/#findme" target="_blank" rel="noopener noreferrer" aria-label='my wechat subscription acct.'>
              <FontAwesomeIcon icon={faWeixin} style={{ fontSize: '16px' }} />
            </a>
            <a href="https://twitter.com/ramblezh" style={{ marginLeft: '20px' }} target="_blank" rel="noopener noreferrer" aria-label='twitter/X'>
              <FontAwesomeIcon icon={faXTwitter} style={{ fontSize: '16px' }} />
            </a>
            <a href="https://t.me/imzhme" style={{ marginLeft: '20px' }} target="_blank" rel="noopener noreferrer" aria-label='Telegram'>
              <FontAwesomeIcon icon={faTelegram} style={{ fontSize: '16px' }} />
            </a>
            <a href="/rss.xml" style={{ marginLeft: '20px' }} target="_blank" rel="noopener noreferrer" aria-label='RSS'>
              <FontAwesomeIcon icon={faRss} style={{ fontSize: '16px' }} />
            </a>

            <Popover content={content} title={t('sharebutton')} trigger="click" >
              <FontAwesomeIcon icon={faShareNodes} style={{ fontSize: '16px', marginLeft: '20px', cursor: 'pointer' }} />
            </Popover>
          </div>
          <p className="text-xs text-gray-300">
            Copyright © 2022-2024 John Zhang, All rights reserved.
          </p>
        </footer>
        <a href="https://clustrmaps.com/site/1bxg8" title="Visit tracker">
          <img src="//www.clustrmaps.com/map_v2.png?d=tgRujFovarlV2bC7vVqN0T6xsdjfxnkjuNjT8O4qoUg&cl=ffffff"
            style={{ width: '30%', display: 'none' }} />
        </a>
      </div>
    </ConfigProvider>
  );
};

export default App;

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import { I18nextProvider } from 'react-i18next'; // 导入 I18nextProvider
import i18n from './i18n'; // 导入你的 i18n 配置
import { ThemeProvider } from './components/ThemeSwitcher'; // 请将这里的路径替换为你的 ThemeContext 文件的路径

const root = document.getElementById('root');

if (root) {
  const app = (
    <React.StrictMode>
        <ThemeProvider>
        <I18nextProvider i18n={i18n}>
          <App />
        </I18nextProvider>
      </ThemeProvider>
    </React.StrictMode>
  );

  // 使用 createRoot 替代 render
  ReactDOM.createRoot(root).render(app);
}

import React, { useState, useEffect, createContext } from 'react';
import PropTypes from 'prop-types';

// 创建一个主题的上下文
export const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
    // const currentTime = new Date().getHours();
    // const isDayTime = currentTime > 6 && currentTime < 23;
    // const defaultTheme = isDayTime ? 'light' : 'dark';
    // const [theme, setTheme] = useState(localStorage.getItem('theme') || defaultTheme);

    const defaultTheme = 'light';
    const [theme, setTheme] = useState(localStorage.getItem('theme') || defaultTheme);

    useEffect(() => {
        localStorage.setItem('theme', theme);
        if (theme === 'dark') {
            document.documentElement.classList.add('dark');
            document.querySelector('meta[name="theme-color"]').setAttribute('content', '#0d1117');
            document.querySelector('meta[name="apple-mobile-web-app-status-bar-style"]').setAttribute('content', 'black-translucent');
        } else {
            document.documentElement.classList.remove('dark');
            document.querySelector('meta[name="theme-color"]').setAttribute('content', '#fff');
            document.querySelector('meta[name="apple-mobile-web-app-status-bar-style"]').setAttribute('content', 'default');
        }
    }, [theme]);

    const toggleTheme = () => {
        setTheme(prevTheme => prevTheme === 'light' ? 'dark' : 'light');
    };

    return (
        <ThemeContext.Provider value={{ theme, toggleTheme }}>
            {children}
        </ThemeContext.Provider>
    );
};

ThemeProvider.propTypes = {
    children: PropTypes.node,
};
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: {
            'home': 'Home',
            'changetochinese': 'Chinese',
            'snoweffect': 'Happy Winter!',
            'fireworkseffect': 'Happy New Year!',
            'darkmode': 'Dark Mode',
            'rambles': 'Time like these',
            'returntohome': 'Return to Home',
            'sharebutton': 'Share this to',
            "linkCopied": "Link Copied!",
            "copyFailed": "Copy Failed",
            'aura': 'Aura',
            'douban': 'Go Douban',
            'mymovies': 'Usher',
            'mymusics': 'Resonance',
            'mybooks': 'Anthology',
            'books': 'Books',
            'movies': 'Movies',
            'musics': 'Music',
            'posts': 'Posts',
            'random': 'Random',
            'about': 'About',
            'all': 'All',
            'music': 'Audios',
            'picture': 'Pictures',
            'timelikethese': 'Rambles',
            'about-introduction': 'Introduction',
            'about-introduction-1': `Here, there's nothing new! Just some mundane, tedious, random ramblings. I named it [ramble], meaning to wander aimlessly, yes, just like this purposeless drifting. Beyond the ramblings, there's an aura called "Halo," where I place my literary and audiovisual creations. They serve as guiding lights in my wanderings, constantly leading me to new explorations.`,
            'about-introduction-2': 'All content on this website is for personal hobbies and does not involve any commercial activities.',
            'about-introduction-3': ' All audio and video content comes from the Internet and is for learning and communication only.',
            'about-introduction-4': ' If any audio and video content infringes your rights, please contact us through',
            'about-introduction-contact': ' CONTACT US',
            'about-introduction-5': ' We will delete the infringing content immediately after receiving the notice.',
            'about-introduction-6': ' Unless otherwise specified, all text and pictures on this website are original content. If there is any similarity, it is a coincidence.',
            'about-introduction-7': ' The copyright of all original content belongs to us.',
            'about-introduction-8': ' to get permission if you want to reprint our content.',
            'about-log': 'Update log',
            'about-log-pending':'Coming soon!',
            'about-log-0':'Successfully launched the website project!',
            'about-log-1': 'Implemented fundamental features such as post viewing, commenting, and more. The foundational elements are now in place, marking the completion of the embellishment of the Ramble website.',
            'about-log-2': 'The webpage is now live, enabling seamless browsing of all nuggets with paging functionality. Furthermore, multimedia files embedded within nuggets are now previewable.',
            'about-log-3': 'Now offering support for multiple languages, including English (default), Chinese, and upcoming Emoji support. Additionally, a light/dark theme switcher has been implemented.',           
            'about-log-4': 'Added an RSS subscription button, TG channel link, WeChat public account, and a convenient share button to the footer.',
            'about-log-5': 'Enhanced the user experience by streamlining the main interface, eliminating intricate color schemes in favor of a sleek black-and-white design. Additionally, relocated the dark theme toggle and language switch functionality from the menu bar to a more accessible bottom floating button. Introduced a captivating ⌈snowfall⌋ effect to usher in the festive season. Wishing everyone a joyous Christmas and a prosperous New Year.',
            'about-log-6': `Enhanced the Aura menu with a refined collection of books, movies, and musics, gathering my favorite content onto dedicated pages. Introducing dynamic effects (best experienced on PC) and a drawer feature to display detailed information, including Douban links, synopses, and embedded playback options for Spotify and Apple Music. For videos, embedded blocks for Tencent Video, YouTube, and Bilibili have been incorporated. Simultaneously, optimized the sitemap page. Lastly, temporarily replaced the winter snowfall effect with a festive fireworks display to ring in the New Year. Wishing everyone a joyful New Year, and let's continue the journey in 2024.`,
            'about-log-7': `The website is facing notable performance issues, particularly on mobile devices. The LCP on mobile has reached 4.5 seconds, and the TBT is also at 1200 milliseconds. I attempted to address this issue using lazy loading techniques; however, due to the abundance of JavaScript files, I observed a significant memory consumption related to data problems in the Treemap. Given the static nature of the website, conventional pagination methods seem challenging to implement. The current pagination loads all data on the frontend. If you have any suggestions, please feel free to reach out to me.`,
            'about-status': 'Statistics',
            'about-status-1': 'Total Words: {{count}} words',  
            'about-status-2': 'Total Rambles: {{count}} rambles',        
            'about-status-3': 'Successfully Runs: {{count}} days',  
            'about-status-4': 'To be arranged',                                      
            'about-credits': 'Credits',
            'about-credits-template': 'Interface Template',
            'about-credits-css': 'CSS Library',
            'about-credits-ui': 'UI Library',
            'about-credits-domain': 'Free Domain',
            'about-credits-deployment': 'Project Deployment',
            'about-credits-ssl': 'SSL Certificate',
            'about-credits-library': 'Development Library',
            'about-credits-imagehost': 'Image Hosting',
            'about-credits-comments': 'Comment Support',
            'about-credits-components': 'Component Support',
            'about-credits-media': 'AVs External Link',
            'about-credits-tech': 'Technical Support',
            'about-credits-music': 'Backup Music Host',
            'about-credits-reshot': 'Free Stock Photos',
            'about-credits-foreverblog': 'Forever Blog',
            'about-brand': 'Identity',
            'about-findme': 'Find me',
            'about-messageboard': 'Leave a Message',
            // 在这里添加你的其他英文翻译
        }
      },
      zh: {
        translation: {
            'home': '主页',
            'changetochinese': '中文',
            'snoweffect': '下雪啦！',
            'fireworkseffect': '新年快乐！',
            'darkmode': '暗黑主题',
            'rambles': '碎碎念',
            'returntohome': '返回主页',
            'sharebutton': '分享到',
            "linkCopied": "链接已复制！",
            "copyFailed": "复制失败",
            'aura': '书影音',
            'douban': '前往豆瓣',
            'mymovies': '我的影片架',
            'mymusics': '我的唱片架',
            'mybooks': '我的书架',
            'books': '书',
            'movies': '影',
            'musics': '音',
            'posts': '文章',
            'random': '随机',
            'about': '关于',
            'all': '全部',
            'music': '声音',
            'picture': '影像',
            'timelikethese': '碎碎念',
            'about-introduction': '介绍',
            'about-introduction-1': '这里，没有新鲜事！有的只是一些无聊的，枯燥的，随机的，碎碎念罢了。我把它取名字叫 ramble，意思是漫游，是的，就是这样的漫无目的的浮游。碎碎念之外，还有一个 aura 叫做光环，我把我的书影音放在了这里，它们是我漫游的灯光指引，让我不断开启新的漫游。',
            'about-introduction-2': '本网站的所有内容仅为个人爱好，不涉及任何商业行为。',
            'about-introduction-3': '所有音视频内容均来自互联网，仅供学习和交流使用。',
            'about-introduction-4': '如果任何音视频内容侵犯了您的权益，请通过',
            'about-introduction-contact': '联系我们',
            'about-introduction-5': '我们将在收到通知后立即删除侵权内容。',
            'about-introduction-6': '除非特别注明，本网站上的所有文字和图片均为原创内容。如有雷同，纯属巧合。',
            'about-introduction-7': '所有原创内容的版权均由本人所有。如果您希望转载我们的内容，请通过',
            'about-introduction-8': '获取许可。',
            'about-log': '更新日志',
            'about-log-pending':'敬请期待！',
            'about-log-0': '网站正式上线啦！',
            'about-log-1': '实现了按文章查看、评论等基本功能。基础要素已经就绪，标志着碎碎念网站的装修工作已经完成。',
            'about-log-2': '网站已上线，支持所有碎碎念的流畅浏览，具备分页功能。此外，碎碎念中的多媒体文件现在支持预览。',
            'about-log-3': '现在支持多种语言，包括英语（默认）、中文和即将推出的表情符号支持。此外，新增了明暗主题切换功能。',
            'about-log-4': '在底部新增了RSS订阅按钮、TG频道链接、微信公众号，以及便捷的分享按钮。',
            'about-log-5': '化繁为简；优化用户体验，简化主界面，以简洁的黑白设计替代繁琐的色彩方案。同时，将暗黑主题切换和语言切换功能从菜单栏移到更易访问的底部浮动按钮。引入引人入胜的⌈下雪⌋效果，迎接欢乐的节日季节。祝大家圣诞快乐，新年进步。',
            'about-log-6': '完善了 Aura（光环） 菜单下面的书影音，将我喜欢的书影音整理到了专门的页面。同时增加了一些动效（在PC上体验效果最佳），并添加了一个抽屉，用于显示书影音的详细信息，包括豆瓣链接、简介信息以及 Spotify 和 Apple Music 的嵌入播放。视频方面，还包括腾讯视频、YouTube 和哔哩哔哩的嵌入格式。同时，对网站导航（Sitemap）页面进行了优化。最后，为了迎接新年，临时将冬天的雪花效果替换为烟花效果。祝愿大家新年快乐，期待在 2024 年继续共同探索。',            
            'about-log-7': `网站的加载速度存在问题，尤其在手机上表现尤为严重。手机上的 LCP 达到了 4.5 秒，而 TBT 也达到了 1200 毫秒。我尝试了一些懒加载的方法来解决这个问题，但由于 JavaScript 文件较多，我在 Treemap 中发现我的数据问题占据了相当大的内存。由于是静态网页，一些常规的分页加载方法似乎不太适用。目前的分页是在前端实现的，加载了全部数据。如果您有任何解决方法，请务必与我联系。`,
            'about-status': '统计',
            'about-status-1': '碎碎念总字数： {{count}} 词',   
            'about-status-2': '碎碎念总条数： {{count}} 条',  
            'about-status-3': '顺利运行： {{count}} 天',   
            'about-status-4': '待安排',      
            'about-credits': '致谢',
            'about-credits-template': '界面模板',
            'about-credits-css': 'CSS 库支持',
            'about-credits-ui': 'UI 库支持',
            'about-credits-domain': '免费域名提供',
            'about-credits-deployment': '项目部署',
            'about-credits-ssl': 'SSL 证书提供',
            'about-credits-library': '开发库支持',
            'about-credits-imagehost': '图床提供',
            'about-credits-comments': '评论支持',
            'about-credits-components': '组件支持',
            'about-credits-media': '音视频外链',
            'about-credits-tech': '技术支持',
            'about-credits-music': '备用音乐外链',
            'about-credits-reshot': '免费素材图片',
            'about-credits-foreverblog': '十年之约',
            'about-brand': '品牌标识',
            'about-findme': '找到我',
            'about-messageboard': '留言板',
          // 在这里添加你的中文翻译
        }
      }
    },
    lng: "en",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;